import React, { useState } from "react"

const ServiceSwitcher = () => {
  const [currentService, setCurrentService] = useState("branding")
  const [services] = useState([
    {
      service: "branding",
      active: true,
    },
    {
      service: "ui",
      active: false,
    },
    {
      service: "website",
      active: false,
    },
    {
      service: "social",
      active: false,
    },
    {
      service: "email",
      active: false,
    },
    {
      service: "webapp",
      active: false,
    },
  ])

  const handleServiceChange = currVal => {
    setCurrentService(currVal)
    services.forEach(i => {
      i.active = false
      if (i.service === currVal) {
        i.active = !i.active
      }
    })
  }

  return (
    <div className="md:grid md:grid-rows-3 md:grid-flow-col md:gap-4 mx-10 xl:mx-50 justify-items-center">
      <div className="md:row-span-3 md:col-span-1">
        <ul className="text-xl xl:text-2xl md:mr-10 lg:mr-0">
          <li className="my-5">
            <button
              className={`hover:border-4 hover:border-customblue hover:text-customblue focus:outline-none ${services.map(
                i => {
                  if (i.service === "branding" && i.active) {
                    return "inline-block text-md px-4 py-4 leading-none rounded border-4 border-customblue text-customblue"
                  }
                  return ""
                }
              )}`}
              onClick={() => {
                handleServiceChange("branding")
              }}
            >
              01. Branding & Logo
            </button>
          </li>
          <li className="my-5">
            <button
              className={`hover:border-4 hover:border-customblue hover:text-customblue focus:outline-none ${services.map(
                i => {
                  if (i.service === "ui" && i.active === true) {
                    return "inline-block text-md px-4 py-4 leading-none rounded border-4 border-customblue text-customblue"
                  }
                  return ""
                }
              )}`}
              onClick={() => {
                handleServiceChange("ui")
              }}
            >
              02. UI/UX Design
            </button>
          </li>
          <li className="my-5">
            <button
              className={`hover:border-4 hover:border-customblue hover:text-customblue focus:outline-none ${services.map(
                i => {
                  if (i.service === "website" && i.active === true) {
                    return "inline-block text-md px-4 py-4 leading-none rounded border-4 border-customblue text-customblue"
                  }
                  return ""
                }
              )}`}
              onClick={() => handleServiceChange("website")}
            >
              03. Websites
            </button>
          </li>
          <li className="my-5">
            <button
              className={`hover:border-4 hover:border-customblue hover:text-customblue focus:outline-none ${services.map(
                i => {
                  if (i.service === "social" && i.active === true) {
                    return "inline-block text-md px-4 py-4 leading-none rounded border-4 border-customblue text-customblue"
                  }
                  return ""
                }
              )}`}
              onClick={() => handleServiceChange("social")}
            >
              04. Social Media
            </button>
          </li>
          <li className="my-5">
            <button
              className={`hover:border-4 hover:border-customblue hover:text-customblue focus:outline-none ${services.map(
                i => {
                  if (i.service === "email" && i.active === true) {
                    return "inline-block text-md px-4 py-4 leading-none rounded border-4 border-customblue text-customblue"
                  }
                  return ""
                }
              )}`}
              onClick={() => handleServiceChange("email")}
            >
              05. Email Marketing
            </button>
          </li>
          <li className="my-5">
            <button
              className={`hover:border-4 hover:border-customblue hover:text-customblue focus:outline-none ${services.map(
                i => {
                  if (i.service === "webapp" && i.active === true) {
                    return "inline-block text-md px-4 py-4 leading-none rounded border-4 border-customblue "
                  }
                  return ""
                }
              )}`}
              onClick={() => handleServiceChange("webapp")}
            >
              06. Web/Mobile Apps
            </button>
          </li>
        </ul>
      </div>
      <div className="md:row-span-3 md:col-span-2 xl:w-9/12 xl:pl-20 mt-10 md:mt-0">
        {currentService === "branding" && (
          <>
            <h2 className="text-2xl xl:text-3xl mt-20 md:mt-0 mb-5 text-center md:text-left">
              Styles That Define You
            </h2>
            <p className="mb-5 text-lg">
              We provide branding from the ground up. No logo? No problem. No
              color scheme? No problem. Let us help you build a brand the way
              you envision it. Branding means everything. A typical logo
              generator or icon tool won’t help you achieve what your after. A
              true custom logo deserves to be made by a professional. Stunning
              look.
            </p>
            <p className="mb-5 text-lg">
              Creating a logo is just the first step. We also help you take all
              of your ideas and compose extensive design guidelines including
              color schemes, fonts, and more.
            </p>
          </>
        )}
        {currentService === "ui" && (
          <>
            <h2 className="text-2xl xl:text-3xl mt-20 md:mt-0 mb-5 text-center md:text-left">
              Design The Way You Envision
            </h2>
            <p className="mb-5 text-lg">
              We help create styles that define your business. Creating a logo
              is just the first step. We also help you take all of your ideas
              and compose extensive design guidelines including color schemes,
              fonts, and more.
            </p>
            <p className="mb-5 text-lg">
              We create wireframes, low fidelity, high fidelity, and production
              ready UI/UX designs. This includes prototyping, presentations, and
              more.
            </p>
          </>
        )}
        {currentService === "website" && (
          <>
            <h2 className="text-2xl xl:text-3xl mt-20 md:mt-0 mb-5 text-center md:text-left">
              Tell Your Story
            </h2>
            <p className="mb-5 text-lg">
              Our team brings over 10 years of website development experience
              working with a variety of different industries. Whether you would
              like a simple informational website, an influencer blog, or an
              e-commerce store, we’ve got you covered.
            </p>
            <p className="mb-5 text-lg">
              Tired of your current website? Are you ready to upgrade to the
              latest design trends? We combine creativity with professionalism
              to give your company a clean and clear look. Tell your story. Your
              website is the first impression. Tell us your ideas and we’ll make
              it happen.
            </p>
          </>
        )}
        {currentService === "social" && (
          <>
            <h2 className="text-2xl xl:text-3xl mt-20 md:mt-0 mb-5 text-center md:text-left">
              Share Everything
            </h2>
            <p className="mb-5 text-lg">
              Digital Marketing Age. It can be difficult keeping up with today’s
              trends. What is popular today, might be outdated tomorrow. Digital
              marketing is an effective, budget friendly option to engage your
              audience in different trendy ways.
            </p>
            <p className="mb-5 text-lg">
              Want to build a social media reputation to develop trust with your
              audience? Our team does extensive research on your industries to
              create a roadmap of marketing content to better target your
              clients.
            </p>
          </>
        )}
        {currentService === "email" && (
          <>
            <h2 className="text-2xl xl:text-3xl mt-20 md:mt-0 mb-5 text-center md:text-left">
              Reach The Inbox
            </h2>
            <p className="mb-5 text-lg">
              Looking to get started with email marketing? Learning the ins and
              outs of email content, subscriber lists, inbox placements, and
              delivery can get quite complex. Our team provides best practices
              and other helpful information like tips & tricks to help your
              overall delivery.
            </p>
            <p className="mb-5 text-lg">
              Creating a stunning email is not as easy as it looks. Design and
              readability is key to grabbing the attention of your readers. Let
              us help design and custom build your emails the way you envision
              it.
            </p>
          </>
        )}
        {currentService === "webapp" && (
          <>
            <h2 className="text-2xl xl:text-3xl mt-20 md:mt-0 mb-5 text-center md:text-left">
              Next Level
            </h2>
            <p className="mb-5 text-lg">
              Sometimes a normal website just isn’t enough. We help create easy
              workflows for your clients to sign in as a user to see paid
              content or even create custom submission forms for your next sales
              lead. Need an All-in-one system? Does your workflow use five
              different app products just to accomplish your daily tasks? Tell
              us how to make things easier, and we’ll make a one stop shop app
              for your tasks.
            </p>
            <p className="mb-5 text-lg">
              Websites and web apps are fun, but mobile apps are awesome. They
              bring your content to the home screen of your clients phones. One
              tap is all it takes to land your next lead, next follower, next
              profit. Our mobile apps are built to run at lightning speeds on
              both Android and iOS devices. We focus not only on creating
              stunning designs and a user friendly experience, but also ease of
              use and increasing conversion rates.
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default ServiceSwitcher
