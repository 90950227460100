import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import "../icons/fontawesome"
import TopHeading from "../components/TopHeading"
import ServiceSwitcher from "../components/ServiceSwitcher"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPeopleArrows,
  faProjectDiagram,
  faShapes,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons"

const services = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marsandi | Services</title>
        <link rel="canonical" href="https://marsandi.com/services" />
        <link rel="icon" href="https://marsandi.com/Marsandi-Logo-color.png" />
        <meta
          name="Description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        ​<meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" contact="@devmarsandi"></meta>
        <meta name="twitter:creator" content="@devmarsandi"></meta>
        <meta
          name="twitter:title"
          content="Bring Your Business To The New Era"
        ></meta>
        <meta
          name="twitter:description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        <meta
          name="twitter:image"
          content="https://marsandi.com/Marsandi-Logo-color.png"
        ></meta>
        ​
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Marsandi" />
        <meta
          property="og:title"
          content="Bring Your Business To The New Era"
        ></meta>
        <meta property="og:url" content="https://marsandi.com"></meta>
        <meta
          property="og:description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        <meta
          property="og:image"
          content="https://marsandi.com/Marsandi-Logo-color.png"
        ></meta>
        <meta property="og:image:alt" content="Logo"></meta>
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="text-customgray">
        <div className="px-2">
          <TopHeading
            title="Tell us your ideas and we’ll make it happen"
            subtitle="We design and develop products to help your business thrive"
          />
        </div>
        <ServiceSwitcher />
        <div className="mt-24 mb-40">
          <div className="px-2">
            <TopHeading
              title="Our Process"
              subtitle="Every project is unique, but every project follows our four step process"
            />
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-10 justify-items-center">
            <div className="flex flex-col items-center mb-20 md:mb-0">
              <FontAwesomeIcon
                icon={faPeopleArrows}
                className="text-customblue text-9xl mb-8"
              />
              <h4 className="text-2xl font-bold mb-5">01. Engage</h4>
              <p className="w-11/12 lg:w-6/12 text-center">
                Our first step is to build a relationship with our point of
                contact. We then gather details on your business goals to
                provide helpful insights on possible solutions.
              </p>
            </div>
            <div>
              <div className="flex flex-col items-center mb-20 md:mb-0">
                <FontAwesomeIcon
                  icon={faProjectDiagram}
                  className="text-customblue text-9xl mb-8"
                />
                <h4 className="text-2xl font-bold mb-5">02. Explore</h4>
                <p className="w-11/12 lg:w-6/12 text-center">
                  Next, we focus on getting to know your industry including the
                  target audience, latest trends, and top competitors. Each
                  company is unique, and its those unique traits that really
                  separate your business from the rest.
                </p>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center mb-20 md:mb-0">
                <FontAwesomeIcon
                  icon={faShapes}
                  className="text-customblue text-9xl mb-8"
                />
                <h4 className="text-2xl font-bold mb-5">03. Build</h4>
                <p className="w-11/12 lg:w-6/12 text-center">
                  Once we have all the information we need, our team of
                  developers will get started on building your dream product.
                </p>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center">
                <FontAwesomeIcon
                  icon={faChartLine}
                  className="text-customblue text-9xl mb-8"
                />
                <h4 className="text-2xl font-bold mb-5">04. Grow</h4>
                <p className="w-11/12 lg:w-6/12 text-center">
                  Once your product has been completed, we help you distribute,
                  advertise, and grow your business. At this point your product
                  or business is well on its way with the tools for positive
                  growth.
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src="/banner-img-1.png" alt="Banner" width="1920" height="auto" />
        <Banner />
      </section>
    </Layout>
  )
}

export default services
